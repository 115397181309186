import React, { useState } from "react";
import axios from "axios";
import Navbar1 from "../Navbar1";
import Marquee from "react-fast-marquee";
import Footer from "../footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import "../style.css";
import MarqueeComponent from "./MarqueeComponent";

const Renewal = () => {
  const [data, setData] = useState({
    name: "",
    user_id: "",
  });

  const [termsChecked, setTermsChecked] = useState(false); // State for checkbox

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if terms and conditions checkbox is checked
    if (!termsChecked) {
      alert("Please accept the terms and conditions.");
      return;
    }

    try {
      const listResponse = await axios.get(
        "http://52.91.230.33:8001/api/new_subscriber/get/list/",
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            user_id: data.user_id,
          },
        }
      );

      if (listResponse.status === 200 && listResponse.data.result.length > 0) {
        const response = await axios.post(
          "http://52.91.230.33:8001/api/renewal_subscriber/update/",
          {
            ...data,
            date_of_payment: "2023-01-01", // Replace with the dummy date for testing
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          console.log("Data sent successfully!");
          window.alert("Data sent successfully!");
          // Reset form data
          setData({
            name: "",
            user_id: "",
          });
        } else {
          console.log("Error occurred while sending data.");
          window.alert("Error occurred while sending data.");
        }
      } else {
        console.log("User ID not found in the list.");
        window.alert("User ID not found in the list.");
      }
    } catch (error) {
      console.log("Error occurred while sending data:", error);
      window.alert("Error occurred while sending data.");
    }
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="./images/web -logo copy.png"
      />

<ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        // viewBox="0 0 256 256"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
        }}
      />

      <MarqueeComponent />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "18px",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="brand-logo"></div>
          <div className="brand-title" id="animate-charcter">
            ANNUAL RENEWAL
          </div>
          <div className="inputs">
            <form onSubmit={handleSubmit}>
              <label>NAME</label>
              <input
                value={data.name}
                onChange={handleInputChange}
                type="text"
                name="name"
                placeholder="Child Name"
              />

              <label>Unique ID</label>
              <input
                value={data.user_id}
                onChange={handleInputChange}
                type="text"
                name="user_id"
                placeholder="Unique ID"
              />

              <div style={{ display: "flex" }}>
                <input
                  style={{
                    width: "20px",
                    height: "20px",
                    boxShadow: "none",
                    marginRight: "8px",
                  }}
                  type="checkbox"
                  checked={termsChecked}
                  onChange={handleTermsChange}
                />
                <label htmlFor="terms">
                  I have read and agreed to the terms and conditions, privacy
                  policy, and Refund policy.
                </label>
              </div>

              <button className="btn-hover color-3" type="submit">
                Renewal Just ₹ 299/-
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Renewal;
