import React, { useState } from "react";
import Navbar1 from "../Navbar1";
import Marquee from "react-fast-marquee";
import Footer from "../footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import "../style.css";
import MarqueeComponent from "./MarqueeComponent";

const NewSubscription = () => {
  const [data, setData] = useState({
    name: "",
    dob: "",
    birth_place: "",
    father_name: "",
    mother_name: "",
    grand_parents_names: "",
    phone_number: "",
    alternative_phone_number: "",
    email: "",
    social_media_links: "",
    upload_first_birthday_pictures: "",
    birthday_picture_discretion: "",
    upload_event_pictures: "",
    picture_discretion: "",
    memorable_moments: "",
    memories_discretion: "",
    upload_family_pictures: "",
    about_family: "",
    more_details: "",
    payments: "",
    payment_id: "",
    payment_status: "",
    date_of_payment: new Date()
      .toLocaleDateString("en-in", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      ?.replaceAll("/", "-"),
    payment_details: "",
  });

  const sendMail = (mailId) => {
    var formdata = new FormData();
    formdata.append("to", mailId);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("http://52.91.230.33:8001/api/email/sent/", requestOptions);
  };

  const handleClick = async (event) => {
    event.preventDefault();

    try {
      const formattedData = {
        ...data,
        dob: formatDate(data.dob), // Format date of birth to "DD-MM-YYYY" format
      };
      var formdata = new FormData();

      for (var key in formattedData) {
        formdata.append(key, formattedData[key]);
      }
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      console.log("Formatted Data:", formdata); // Display the formatted data in the console

      // const response = await axios.post(
      //   "http://52.91.230.33:8001/api/new_subscriber/create/",
      //   // formattedData,
      //   // {
      //   //   headers: {
      //   //     "Content-Type": "application/json",
      //   //   },
      //   // }
      // );
      const response = await fetch(
        "http://52.91.230.33:8001/api/new_subscriber/create/",
        requestOptions
      );

      if (response.status === 200) {
        console.log("Data sent successfully!");
        window.alert("Data sent successfully!");
        sendMail(data?.email);
        resetFormData();
      } else {
        console.log("Error occurred while sending data.");
        window.alert("Error occurred while sending data.");
      }
    } catch (error) {
      console.log("Error occurred while sending data:", error);
      window.alert("Error occurred while sending data.");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // Return an empty string or handle the empty case as per your requirements
    }

    const [year, month, day] = dateString.split("-");
    return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(data, "this is file");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setData((prevState) => ({
      ...prevState,
      upload_first_birthday_pictures: file,
    }));
  };

  const resetFormData = () => {
    setData((prevState) => ({
      ...prevState,
      name: "",
      dob: "",
      birth_place: "",
      father_name: "",
      mother_name: "",
      grand_parents_names: "",
      phone_number: "",
      alternative_phone_number: "",
      email: "",
      social_media_links: "",
      upload_first_birthday_pictures: "",
      birthday_picture_discretion: "",
      upload_event_pictures: "",
      picture_discretion: "",
      memorable_moments: "",
      memories_discretion: "",
      upload_family_pictures: "",
      about_family: "",
      more_details: "",
      payments: "",
      payment_id: "",
      payment_status: "",
      date_of_payment: "20-01-1998",
      payment_details: "",
    }));
  };

  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto. How can we help?"
        avatar="./images/web-logo-copy.png"
      />

<ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        // viewBox="0 0 256 256"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
        }}
      />


<MarqueeComponent />


      <div
        className="newsub"
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "18px",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="brand-logo"></div>
          <div
            className="brand-title"
            id="animate-charcter"
            style={{ margin: "auto", textAlign: "center" }}
          >
            NEW SUBSCRIBER
          </div>
          <div className="inputs">
            <form>
              <label>NAME</label>
              <input
                value={data.name}
                onChange={handleInputChange}
                type="text"
                name="name"
                placeholder="Child Name"
              />

              <label>DATE OF BIRTH (Children)</label>
              <input
                value={data.dob}
                onChange={handleInputChange}
                type="date"
                name="dob"
                placeholder="Date of Birth"
              />

              <label>BIRTH PLACE (Children)</label>
              <input
                value={data.birth_place}
                onChange={handleInputChange}
                type="text"
                name="birth_place"
                placeholder="Birth place"
              />

              <label>FATHER NAME</label>
              <input
                value={data.father_name}
                onChange={handleInputChange}
                type="text"
                name="father_name"
                placeholder="Father Name"
              />

              <label>MOTHER NAME</label>
              <input
                value={data.mother_name}
                onChange={handleInputChange}
                type="text"
                name="mother_name"
                placeholder="Mother Name"
              />

              <label>GRAND PARENTS NAMES</label>
              <input
                value={data.grand_parents_names}
                onChange={handleInputChange}
                type="text"
                name="grand_parents_names"
                placeholder="Grand Parents Names (paternal, maternal)"
              />

              <label>PHONE NUMBER</label>
              <input
                value={data.phone_number}
                onChange={handleInputChange}
                type="text"
                name="phone_number"
                placeholder="Phone Number"
              />

              <label>ALTERNATIVE PHONE NUMBER</label>
              <input
                value={data.alternative_phone_number}
                onChange={handleInputChange}
                type="text"
                name="alternative_phone_number"
                placeholder="Alternative Phone Number"
              />

              <label>MAIL ID (Parent/children)</label>
              <input
                value={data.email}
                onChange={handleInputChange}
                type="email"
                name="email"
                placeholder="Mail Id"
              />

              <label>SOCIAL MEDIA LINKS</label>
              <input
                value={data.social_media_links}
                onChange={handleInputChange}
                type="text"
                name="social_media_links"
                placeholder="Instagram ID, FaceBook ID..."
              />

              <label>UPLOAD 1st BIRTH DAY PICTURES</label>
              <input
                type="file"
                name="upload_first_birthday_pictures"
                onChange={handleFileChange}
                multiple
              />

              <input
                value={data.birthday_picture_discretion}
                onChange={handleInputChange}
                type="text"
                name="birthday_picture_discretion"
                placeholder="Pictures Description"
              />

              <label>
                UPLOAD SCHOOL/ CULTURAL/ SPORTS/ TOURS/ TRAVELS PICTURES
              </label>
              <input
                type="file"
                name="upload_event_pictures"
                onChange={handleFileChange}
                multiple
              />

              <input
                value={data.picture_discretion}
                onChange={handleInputChange}
                type="text"
                name="picture_discretion"
                placeholder="Pictures Description (School, Cultural...)"
              />

              <label>MEMORABLE MOMENTS</label>

              <input
                type="file"
                name="memorable_moments"
                onChange={handleFileChange}
                multiple
              />

              <input
                value={data.memories_discretion}
                onChange={handleInputChange}
                type="text"
                name="memories_discretion"
                placeholder="Description (Memorable Moments)"
              />

              <label>UPLOAD FAMILY PICTURES</label>

              <input
                type="file"
                name="upload_family_pictures"
                onChange={handleFileChange}
                multiple
              />

              <input
                value={data.about_family}
                onChange={handleInputChange}
                type="text"
                name="about_family"
                placeholder="About Family..."
              />

              <input
                value={data.more_details}
                onChange={handleInputChange}
                style={{ height: "150px" }}
                name="more_details"
                placeholder="More Details...."
              />

              <button
                onClick={handleClick}
                className="btn-hover color-3"
                type="button"
              >
                Subscribe ₹ 2999/-
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewSubscription;
