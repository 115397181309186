import React from "react";
import Homepage from "./components/Homepage";
import { Route, Routes } from "react-router-dom";
import About from "./components/Pages/About";
import Vision from "./components/Pages/Vision";
import Subscription from "./components/Pages/Subscription";
import Portfolio from "./components/Pages/Portfolio";
import FAQ from "./components/Pages/FAQ";
import PrivacyPolicy from "./components/T&C pages/PrivacyPolicy";
import Termsconditons from "./components/T&C pages/TermsConditons";
import RefundCancellationPolicy from "./components/T&C pages/RefundCancellationPolicy";
import ContactUs from "./components/Pages/ContactUs";
import Newsubscription from "./components/Pages/Newsubscription";
import Updatesubscription from "./components/Pages/Updatesubscription";
import Renewal from "./components/Pages/Renewal";
import CulturalFest from "./components/Events/CulturalFest";
import OlympiadGames from "./components/Events/OlympiadGames";
import CulturalFestForm from "./components/Events/Enrollment/CulturalFestForm";
import Enrollments from "./components/Events/Enrollment/Enrollments";
import OlympiadGamesForm from "./components/Events/Enrollment/OlympiadGamesForm";



function App() {
  return (
    <React.Fragment>
      {/* <Homepage /> */}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Termsconditons />} />
        <Route path="/refund" element={<RefundCancellationPolicy />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/newsubscription" element={<Newsubscription />} />
        <Route path="/updatesubscription" element={<Updatesubscription />} />
        <Route path="/renewal" element={<Renewal />} />
        <Route path="/culturalfest" element={<CulturalFest />} />
        <Route path="/olympiadgames" element={<OlympiadGames />} />
        <Route path="/culturalfestform" element={<CulturalFestForm />} />
        <Route path="/enrollments" element={<Enrollments />} />
        <Route path="/olympiadgamesform" element={<OlympiadGamesForm />} />
      </Routes>
    </React.Fragment>
    
  );
}

export default App;
