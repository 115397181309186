import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Button } from "@mui/material";
import Marquee from "react-fast-marquee";
import Footer from "./footer";
import ContactForm from "./Pages/ContactForm";
import { Typography } from "@material-ui/core";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import "./style.css";
import Navbar from "./Navbar1";
import { Link } from "react-router-dom";
import MarqueeComponent from "./Pages/MarqueeComponent";

const Homepage = () => {
  const images = [
    "./images/slideimg-1.jpg",
    "./images/slideimg-2.jpg",
    "./images/slideimg-3.jpg",
    "./images/slideimg-4.jpg",
    // "./images/slideimg-1.jpg",
    // "./images/slide6.jpg",
  ];

  const [index, setIndex] = useState(0);
  const image = images[index];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  return (
    <>
      <Navbar />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="./images/web -logo copy.png"
      />

      <ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        // viewBox="0 0 256 256"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
        }}
      />

      <div className="slideshow-main-div">
        <div key={index}>
          <img
            className="slideshow-image"
            src={image}
            alt={`Image ${index + 1}`}
          />
        </div>
      </div>

      <MarqueeComponent />

      <Stack>
        <Typography style={{ margin: "auto", textAlign: "center" }}>
          <div className="home-heading-sizes" id="animate-charcter">
            THE CHRONICAL BOOK OF ENTITY
          </div>
        </Typography>

        <Typography
          style={{
            width: "80%",
            textAlign: "justify",
            margin: "auto",
            paddingBottom: "30px",
          }}>
          <p>
            Dear parents, we specialize in capturing the pivotal moments of your
            child's life journey and creating a sculpture portfolio entitled
            "THE CHRONICLE BOOK OF ENTITY". Our team continuously updates and
            maintains this portfolio while acting as personal public relations
            representatives for each child. We assist with registering talents,
            achieving awards or breaking records. Additionally, our services
            include maintaining an online presence by promoting activities
            through social media pages to gain sponsorships and government
            grants on behalf of your children.
          </p>
        </Typography>
      </Stack>


      <div className="events1-container">
      <div className="events1-box">
        <img src="./images/cul1.png" alt="success" />
        <h1 className="fest-heading">TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN</h1>
        <p className="fest-p">EVENT ORGANISED BY LIFEHISTO.COM PVT LTD</p>
        <p className="fest-p">VENUE: GD GOENKA SCHOOL LB NAGAR</p>
        <Link to="/culturalfest" style={{ textDecoration: "none" }}>
          <Button className="btn-hover color-3" variant="contained" color="primary">
            Learn More
          </Button>
        </Link>
      </div>
      <div className="events1-box">
        <img src="./images/cricket.png" alt="visionary" />
        <h1 className="fest-heading">TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024</h1>
        <p className="fest-p">EVENT ORGANISED BY LIFEHISTO.COM PVT LTD</p>
        <p className="fest-p">VENUE: GD GOENKA SCHOOL LB NAGAR</p>
        <Link to="/olympiadgames" style={{ textDecoration: "none" }}>
          <Button className="btn-hover color-3" variant="contained" color="primary">
            Learn More
          </Button>
        </Link>
      </div>
    </div>

      


      <Typography style={{ margin: "auto", textAlign: "center" }}>
        <div
          id="animate-charcter"
          classNameName="home-heading-sizes"
          style={{ fontSize: "42px", marginTop: "10px" }}>
          Activities
        </div>
      </Typography>

      <Grid
        container
        sx={{
          flexDirection: "row",
          width: "50%",
          margin: "auto",
          height: "auto",
          color: "#fff",
        }}>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={{ height: "250px", padding: "10px" }}>
          <Stack>
            <iframe
              style={{ borderRadius: "20px" }}
              title="Unique Title1 Here"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="200px"
              type="text/html"
              margin="auto"
              src="https://www.youtube.com/embed/ah7-v8lPWyI?si=kjgVnUD3qRtvaYYp"></iframe>
          </Stack>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={{ height: "250px", padding: "10px" }}>
          <Stack>
            <iframe
              style={{ borderRadius: "20px" }}
              title="Unique Title2 Here"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="200px"
              type="text/html"
              margin="auto"
              src="https://www.youtube.com/embed/9SePOizW26w?si=i7MMn7nWWgTQkCrh"></iframe>
          </Stack>
        </Grid>

        {/* <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{ height: "250px", padding: "10px" }}>
          <Stack>
            <iframe
              title="Unique Title3 Here"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="200px"
              type="text/html"
              margin="auto"
              src="https://www.youtube.com/embed/tmgDvHRu6d4?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"></iframe>
          </Stack>
        </Grid>

        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{ height: "250px", padding: "10px" }}>
          <Stack>
            <iframe
              title="Unique Title4 Here"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="200px"
              type="text/html"
              margin="auto"
              src="https://www.youtube.com/embed/tmgDvHRu6d4?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"></iframe>
          </Stack>
        </Grid> */}
      </Grid>

      <ContactForm />

      <Footer />
    </>
  );
};

export default Homepage;
