import React, { useState } from "react";
import axios from "axios";
import Navbar1 from "../Navbar1";
import Marquee from "react-fast-marquee";
import Footer from "../footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import MarqueeComponent from "./MarqueeComponent";

const Updatesubscription = () => {
  const [data, setData] = useState({
    name: "",
    user_id: "",
    upload_pics: null,
    message: "",
  });

  const handleClick = () => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("user_id", data.user_id);
    formData.append("upload_pics", data.upload_pics);
    formData.append("message", data.message);

    axios
      .post("http://52.91.230.33:8001/api/new_post/create/", formData)
      .then((response) => {
        if (response.status === 200) {
          console.log("Data sent successfully!");
          window.alert("Data sent successfully!");
          setData({
            name: "",
            user_id: "",
            upload_pics: null,
            message: "",
          });
        } else {
          console.log("Error occurred while sending data.");
          window.alert("Error occurred while sending data.");
        }
      })
      .catch((error) => {
        console.log("Error occurred while sending data:", error);
        window.alert("Error occurred while sending data.");
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setData((prevState) => ({
      ...prevState,
      upload_pics: file,
    }));
  };

  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="./images/web -logo copy.png"
      />

<ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        // viewBox="0 0 256 256"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
        }}
      />

      <MarqueeComponent />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "18px",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="brand-logo"></div>
          <div className="brand-title" id="animate-charcter">
            EXISTING SUBSCRIBER
          </div>
          <div className="inputs">
            <form>
              <label>NAME</label>
              <input
                type="text"
                placeholder="Child Name"
                value={data.name}
                name="name"
                onChange={handleInputChange}
              />

              <label>USER ID</label>
              <input
                type="text"
                placeholder="User ID"
                value={data.user_id}
                name="user_id"
                onChange={handleInputChange}
              />

              <label>UPLOAD PICTURE</label>
              <input
                type="file"
                placeholder="Upload Picture"
                name="upload_pics"
                onChange={handleFileChange}
              />

              {/* <label>CONTACT MESSAGE</label> */}
              <input
                value={data.message}
                name="message"
                onChange={handleInputChange}
                style={{ height: "150px" }}
                placeholder="About Content..."
              />

              <button
                onClick={handleClick}
                className="btn-hover color-3"
                type="button"
              >
                UPDATE
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Updatesubscription;
