import { React, useState } from "react";
import Navbar1 from "../Navbar1";
import axios from "axios";
import Marquee from "react-fast-marquee";
import Footer from "../footer";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import MarqueeComponent from "./MarqueeComponent";

const Subscription = () => {

  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="./images/web -logo copy.png"
      />

<ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        // viewBox="0 0 256 256"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
        }}
      />

      <MarqueeComponent />

      <Typography
        style={{
          width: "80%",
          margin: "auto",
          padding: " 25px 0px",
          textAlign: "center",
        }}>
        <h1
          id="animate-charcter"
          className="home-heading-sizes"
          style={{
            textAlign: "center",
          }}>
          Subscription
        </h1>
      </Typography>

      <Typography
        style={{
          width: "80%",
          margin: "auto",
          textAlign: "center",
          padding: "25px",
          fontSize: "22px",
        }}>
        By enrolling in LifeHisto, each guardian has the chance to enhance and archive all of their offspring's life experiences, commencing from their initial arrival into this world.
      </Typography>

      <Grid container style={{ margin: "40px 0px 100px 0px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography>
            <Link
              style={{ textDecoration: "none", color: "gray" }}
              to="/newsubscription">
              <button
                style={{
                  width: "80%",
                  height: "70px",
                  fontSize: "18px",
                  margin: "auto",
                  backgroundColor: "rgb(54,225,253)",
                  backgroundImage:
                    "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
                }}
                class="btn-hover color-3">
                NEW SUBSCRIBER
              </button>
            </Link>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography>
            <Link
              style={{ textDecoration: "none", color: "gray" }}
              to="/subscription">
              <button
                style={{
                  width: "80%",
                  height: "70px",
                  fontSize: "18px",
                  margin: "auto",
                  backgroundColor: "rgb(54,225,253)",
                  backgroundImage:
                    "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
                }}
                class="btn-hover color-3">
                EXISTING SUBSCRIBER<br/><span style={{color:'red'}}>Comming Soon</span>
              </button>
            </Link>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography>
            <Link
              style={{ textDecoration: "none", color: "gray" }}
              to="/subscription">
              <button
                style={{
                  width: "80%",
                  height: "70px",
                  fontSize: "18px",
                  margin: "auto",
                  backgroundColor: "rgb(54,225,253)",
                  backgroundImage:
                    "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
                }}
                class="btn-hover color-3">
                ANNUAL RENEWAL<br/><span style={{color:'red'}}>Comming Soon</span>
              </button>
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Subscription;
