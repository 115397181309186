import { Typography } from "@mui/material";
import React from "react";
import Navbar1 from "../Navbar1";
import Footer from "../footer";

const TermsConditions = () => {
  return (
    <>
      <Navbar1 />
      
      <Typography
        style={{
          margin: "auto",
          textAlign: "center",
          fontSize: "36px",
          fontWeight: "bolder",
          padding: "25px",
        }}>
        TERMS AND CONDITIONS
      </Typography>

      <Typography
        style={{
          width: "80%",
          margin: "auto",
          fontSize: "24px",
          paddingBottom: "10px",
          textAlign:'justify',
        }}>
        Welcome to LifeHisto, where we are committed to providing parents and
        guardians with a platform to celebrate their child's achievements
        through beautifully designed portfolios. Below are our terms and
        conditions:
      </Typography>

      <Typography style={{ width: "80%", margin: "auto", padding:'10px',textAlign:'justify' }}>
        1. Subscription: Our subscribers can access our portfolio design
        services for a fee of ₹299 per year.
        <br></br>
        2. Content Uploads: Parents or guardians can upload the content for
        their child's portfolio once a quarter, or four times a year, free of
        charge.
        <br></br>
        3.Portfolio Renewal: The renewal date for each portfolio will be on the
        first day of the financial year, which is April 1st. The renewal fee is
        ₹299 per portfolio.
        <br></br>
        4. Late Renewal: If the renewal fee is not paid by the due date, the
        portfolio will be put on hold and will not be displayed until the
        renewal fee is paid.
        <br></br>
        5. Acceptable Email:Only official emails provided during the application
        process will be accepted for content submission.
        <br></br>
        6. Third-Party Activities: LifeHisto is not responsible for any
        third-party activities that subscribers may choose to participate in
        while using our services.
      </Typography>

      <Typography
        style={{
          width: "80%",
          margin: "auto",
          fontSize: "18px",
          padding: "10px",
          textAlign:'justify'
        }}>
        At LifeHisto, we strive to provide our subscribers with quality services
        that celebrate their child's achievements. We encourage our subscribers
        to carefully read and understand our terms and conditions to ensure a
        smooth experience with our platform. If you have any questions or
        concerns, please do not hesitate to reach out to our customer service
        team
      </Typography>

      <Footer />
    </>
  );
};

export default TermsConditions;
