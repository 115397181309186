import React, { useState } from "react";
import { Stack } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import { Link } from "react-router-dom";

const ContactForm = () => {
    const [data, setData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    
      const handleClick = (event) => {
        event.preventDefault();
    
        axios
          .post("http://52.91.230.33:8001/api/contact/create/", data)
          .then((response) => {
            if (response.status === 200) {
              console.log("Data sent successfully!");
              window.alert("Data sent successfully!");
              setData({
                name: "",
                email: "",
                subject: "",
                message: "",
              });
            } else {
              console.log("Error occurred while sending data.");
              window.alert("Error occurred while sending data.");
            }
          })
          .catch((error) => {
            console.log("Error occurred while sending data:", error);
            window.alert("Error occurred while sending data.");
          });
      };
    
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

  return (
    <>
      <div className="contact" id="contact" style={{color:'#fff'}}>
        <div className="max-width">
            <h2 style={{textAlign:'center', fontSize:'32px'}}>Get in Touch</h2>
            <div className="contact-content">
                <div className="column left">
                    <div className="icons">
                        <div className="row">
                            <PersonIcon style={{fontSize:'34'}} />
                            <div className="info">
                                <div className="head">Name</div>
                                <div className="sub-title">LifeHisto</div>
                            </div>
                        </div>
                        <div className="row">
                            <LocationOnIcon style={{fontSize:'34'}} />
                            <div className="info">
                                <div className="head">Address</div>
                                <div className="sub-title">2-48/5/6, Gachibowli Rd, Opp. RTTC, Telecom Nagar Extension, Gachibowli,Hyderabad, Telangana 500032</div>
                            </div>
                        </div>
                        <div className="row">
                            <EmailIcon style={{fontSize:'34'}} />
                            <div className="info">
                                <div className="head">Email</div>
                                <div className="sub-title">lifehisto@gmail.com</div>
                            </div>
                        </div>
                        <div className="row">
                            <CallIcon style={{fontSize:'34'}} />
                            <div className="info">
                                <div className="head">Phone</div>
                                <div className="sub-title">+91 9293225555</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="column right">
              <div className="text">Contact Now</div>
              <form id="form">
                <div className="fields">
                  <div className="field name">
                    <input
                      value={data.name}
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Name"
                      name="name"
                      id="name"
                      required
                    />
                  </div>
                  <div className="field email">
                    <input
                      value={data.email}
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Email"
                      name="email"
                      id="email"
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <input
                    value={data.subject}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    id="subject"
                    required
                  />
                </div>

                <div className="field textarea">
                  <input
                    value={data.message}
                    onChange={handleInputChange}
                    type="text"
                    cols="30"
                    rows="10"
                    name="message"
                    placeholder="Message.."
                    id="message"
                    required
                  />
                </div>
                <div className="button-area">
                  <input
                    onClick={handleClick}
                    style={{
                      backgroundColor: "#fc17fa",
                      boxShadow: "none",
                    }}
                    type="submit"
                    className="button-contact-btn"
                    value="Send Email"
                  />
                </div>
              </form>
            </div>
            </div>

            <br></br>

<div className="contact-content">
            <div className="column left">
              <div className="text">Follow us on:</div>
              <Stack id="Followuson"
                className="icons"
                style={{ display: "flex",placeItems:'flex-start' }}>
                
                <span class="background">
                  <span class="social-media-buttons">
                    <span class="social-media-button">
                      <a href="https://www.instagram.com/lifehisto?igsh=MXNlZnAzMHk4NGl3dw==">
                      <img
                        alt="Instagram"
                        src="images/instagram.svg"
                      />
                      </a>
                    </span>

                    <span class="social-media-button">
                    <a href="https://twitter.com/HistoLife3162?s=09">
                      <img
                        alt="Twitter"
                        src="images/twitter.svg"
                      />
                      </a>
                    </span>

                    <span class="social-media-button">
                    <a href="https://www.youtube.com/@lifehisto">
                    <img
                        alt="Youtube"
                        src="images/youtube.svg"
                      />
                      </a>
                    </span>

                    <span class="social-media-button">
                    <a href="https://www.facebook.com/share/mHrnYYsEZgRxkoHD/?mibextid=LQQJ4d">
                    <img
                        alt="facebook"
                        src="images/facebook.svg"
                      />
                      </a>
                    </span>
                  </span>
                </span>
              </Stack>
            </div>
          </div>
          
                </div>
      </div>
    </>

    
  )
}

export default ContactForm;