import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Navbar1 from "../Navbar1";
import Marquee from "react-fast-marquee";
import Footer from "../footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import MarqueeComponent from "./MarqueeComponent";

const Vision = () => {
  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="./images/web -logo copy.png"
      />

<ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        // viewBox="0 0 256 256"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
        }}
      />

      <MarqueeComponent />

      <Grid container className="vision-main-box">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="vision-main-box1">
          <Typography style={{ margin: "auto", textAlign: "center" }}>
            <div
              id="animate-charcter"
              style={{ fontSize: "50px", marginTop: "10px" }}>
              Our Vision & Mission
            </div>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="vision-main-box2">
          <img src="./images/vision-test.png" alt="Vision Main Face Gif" />
        </Grid>
      </Grid>

      <Grid
        container
        style={{ width: "80%", margin: "auto", textAlign: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center", padding: "15px" }}>
          <Stack sx={{ width: "100%", height: "85%", objectFit: "contain" }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
                borderRadius: "25px",
              }}
              src="./images/vision-gif1.gif"
              alt="Vision gif1"
            />
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center", padding: "15px" }}>
          <h1 id="animate-charcter" style={{ fontSize: "20px" }}>
            WHY SHOULD YOU CHOOSE US? OUR VISION & MISSION
          </h1>
          <p style={{ textAlign: "justify" }}>
            Our aim is to create a digital representation of each person's life
            journey. As expert service providers, we are committed to
            facilitating individuals in discovering suitable opportunities and
            collaborators for their success. Our overarching objective is to
            establish ourselves as the primary reference source akin to
            "Wikipedia" regarding the biographies of all prospective global
            residents. At Lifehisto, our mission comprises exclusively capturing
            comprehensive career statistics pertaining specifically to every
            child residing within India by developing bespoke online portfolios
            that highlight essential personal details such as family background
            information along with significant events like school admissions
            ceremonies or birthdays.
          </p>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ width: "80%", margin: "auto", textAlign: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center", padding: "15px" }}>
          <h1 id="animate-charcter" style={{ fontSize: "20px" }}>
            OUR CORE VALUES
          </h1>
          <p style={{ textAlign: "justify" }}>
            Innovation lies at the heart of our enterprise. Our constant
            endeavor is to explore novel ways to enhance and refine our products
            and services, with an unwavering commitment towards staying ahead in
            the industry's forefront. We are fervent about developing innovative
            solutions that facilitate greater ease and efficiency for our
            clients' lives. At Lifehisto, we remain steadfastly devoted to
            embracing integrity, innovation, as well as inclusivity - which form
            integral components of our core values system on a daily basis. We
            strongly believe these principles play indispensable roles in both
            driving success for ourselves while simultaneously steering us
            closer towards creating a better future for all stakeholders
            including employees & partners apart from customers too! In
            conclusion; therefore it remains unequivocally true: lifehisto
            believes wholeheartedly that adhering staunchly by its morals
            comprising Integrity-Innovation-inclusivity shall undoubtedly unlock
            positive outcomes making people's lives more meaningful &
            productive- thus immensely contributing toward achieving its mission
            objectives besides ensuring their incorporation into every aspect
            related thereof without compromise or exception whatsoever!.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center", padding: "15px" }}>
          <Stack sx={{ width: "100%", height: "85%", objectFit: "contain" }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
                borderRadius: "25px",
              }}
              src="./images/vision-gif2.gif"
              alt="Vision gif2"
            />
          </Stack>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default Vision;
