
import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import { Stack } from "@mui/material";
import './style.css'; 

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(18),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "#000",
    fontSize: "15px",
    
    marginLeft: theme.spacing(5),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "#fc17fa",
      // borderBottom: "2px solid #36e1fd",
      fontSize:'15.5px'
      
    },
  },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="sticky">
      <CssBaseline />
      <Toolbar style={{justifyContent:'space-between'}}>
        <Link to="/" className={classes.link}>
        <Stack
              >
              <img className="main-logo"
                src="./images/LifeHisto Logo.png"
                alt="main-logo"
              />
            </Stack>
            </Link>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>
            HOME
            </Link>
            <Link to="/about" className={classes.link}>
            ABOUT US & TEAM
            </Link>
            <Link to="/vision" className={classes.link}>
            VISION OF COMPANY
            </Link>
            <Link to="/subscription" className={classes.link}>
            SUBSCRIPTION
            </Link>
            <Link to="/portfolio" className={classes.link}>
            PORTFOLIO
            </Link>
            <Link to="/faq" className={classes.link}>
              FAQ
            </Link>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;

