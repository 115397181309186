import React from 'react';
import './OlympiadGames.css'; // Ensure you create this CSS file with styles
import Navbar from '../Navbar1';
import Footer from '../footer';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const OlympiadGames = () => {
  return (
    <>
    <Navbar />


    <div className="OlympiadGames-container">

    <div className='cult-img-div'>
      <img src='./images/cricket.png' />
      </div>

      <header className="header">
      <h1>Welcome to the Telangana State School Olympiad Games 2024!</h1>
      </header>

      
      <p>
        Join us from November 15-17, 2024, at GD Goenka School, LB Nagar, Hyderabad, for an exciting three-day sports extravaganza. Sponsored by Learnorama Technologies Pvt Ltd and organized by Lifehisto.com Pvt Ltd, this event provides a platform for students aged 7 to 15 to showcase their athletic skills, compete for fantastic prizes, and earn recognition. Free dormitory-type accommodation and food will be provided for all players and coaches.
      </p>

      <h2>Event Descriptions and Concepts</h2>

      <h1 style={{margin:'auto',textAlign:'center'}}>Event Overview</h1>
      <div style={{textAlign: 'center', margin: '20px 0'}}>
        <a 
          href="https://learnoramabackend.learnorama.in/pdfs/OLYMPIAD.pdf" 
          download 
          // className="pdf-download-button"
          class="btn-hover color-3"
          //  className="button-2"
          style={{
            display: 'inline-block',
            padding: '10px 20px',
            backgroundColor: '#004AAD',
            color: '#fff',
            textDecoration: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            fontWeight: 'bold'
          }}
        >
          Download PDF
        </a>
      </div>

      <Link to="/enrollments" style={{ textDecoration: "none" }}>
          <Button className="btn-hover color-3" variant="contained" color="primary">
          Enroll Now
          </Button>
        </Link>

      <h3>Team Sports</h3>
      <p><strong>Cricket:</strong></p>
      <ul>
        <li>Categories: U11 and U15</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Teams compete in limited-overs matches. Judged on runs scored and wickets taken.</li>
        <li>Entry Fee: ₹3000 per team</li>
      </ul>
      <p><strong>Football:</strong></p>
      <ul>
        <li>Categories: U11 and U15</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Teams compete in matches. Judged on goals scored.</li>
        <li>Entry Fee: ₹3000 per team</li>
      </ul>
      <p><strong>Basketball:</strong></p>
      <ul>
        <li>Categories: U11 and U15</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Teams compete in matches. Judged on points scored.</li>
        <li>Entry Fee: ₹3000 per team</li>
      </ul>

      <h3>Individual Sports</h3>
      <p><strong>Tennis:</strong></p>
      <ul>
        <li>Categories: U7, U9, U11, U13, U15 (Boys and Girls separately)</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Players compete in singles matches. Judged on points scored.</li>
        <li>Entry Fee: ₹1500 per player</li>
      </ul>
      <p><strong>Archery:</strong></p>
      <ul>
        <li>Categories: U7, U9, U11, U13, U15 (Boys and Girls separately)</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Participants shoot arrows at a target. Judged on accuracy and points scored.</li>
        <li>Entry Fee: ₹1500 per participant</li>
      </ul>
      <p><strong>Skating:</strong></p>
      <ul>
        <li>Categories: U7, U9, U11, U13, U15 (Boys and Girls separately)</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Participants compete in races. Judged on speed and finishing time.</li>
        <li>Entry Fee: ₹1500 per participant</li>
      </ul>

      <h3>Track and Field Events</h3>
      <p><strong>100m, 200m, 400m Races:</strong></p>
      <ul>
        <li>Categories: U7, U9, U11, U13, U15 (Boys and Girls separately)</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Participants compete in sprint races. Judged on speed and finishing time.</li>
        <li>Entry Fee: ₹1000 per participant</li>
      </ul>
      <p><strong>Long Jump, High Jump:</strong></p>
      <ul>
        <li>Categories: U7, U9, U11, U13, U15 (Boys and Girls separately)</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Participants compete in jumping events. Judged on distance and height achieved.</li>
        <li>Entry Fee: ₹1500 per participant</li>
      </ul>

      <h3>Chess Tournament</h3>
      <p><strong>Singles:</strong></p>
      <ul>
        <li>Categories: U7, U9, U11, U13, U15 (Boys and Girls separately)</li>
        <li>Rounds: Preliminary and Final</li>
        <li>Concept: Players compete in chess matches. Judged on strategy and checkmates.</li>
        <li>Entry Fee: ₹1000 per player</li>
      </ul>

      <h2>Prizes and Awards</h2>
      <h3>Team Events</h3>
      <ul>
        <li>Winning Team:
          <ul>
            <li>Rolling Trophy and Gold Medal for School</li>
            <li>Gold Medal for each player</li>
            <li>₹10,000 worth gifts and coupons for each player</li>
          </ul>
        </li>
        <li>Runner-Up Team:
          <ul>
            <li>Silver Medal for School and each player</li>
            <li>₹5,000 worth gifts and coupons for each player</li>
          </ul>
        </li>
      </ul>

      <h3>Individual Events</h3>
      <ul>
        <li>First Place: Gold Medal and ₹50,000 worth gifts and coupons</li>
        <li>Second Place: Silver Medal and ₹25,000 worth gifts and coupons</li>
        <li>Third Place: Bronze Medal and ₹10,000 worth gifts and coupons</li>
      </ul>

      <h3>Overall School Awards</h3>
      <ul>
        <li>Maximum Gold Medals Winning School: Telangana State School Olympiad Games 2024 Title with ₹1,00,000 Cash Prize Award</li>
        <li>Maximum Silver Medals Winning School: Runner-Up Trophy with ₹75,000 Cash Prize</li>
        <li>Maximum Bronze Medals Winning School: Third Place Trophy with ₹50,000 Cash Prize</li>
      </ul>

      <p>All participants will receive Merit Certificates for their respective sports activities. The school with the highest overall performance will be awarded a prestigious trophy and recognized as the champion of the Telangana State School Olympiad Games 2024.</p>

      <h2>Accommodation and Food</h2>
      <p>
        Free dormitory-type accommodation and food will be provided for all players and coaches, ensuring a comfortable and pleasant stay during the event.
      </p>

      <h2>Rules and Regulations</h2>
      <ol>
        <li><strong>Eligibility:</strong>
          <ul>
            <li>Participants must be enrolled in a school in Telangana.</li>
            <li>Age categories: U7, U9, U11, U13, U15. Age will be verified based on the participant's school records.</li>
          </ul>
        </li>
        <li><strong>Team Composition:</strong>
          <ul>
            <li>Schools can send two teams per category for team events (U11 and U15).</li>
            <li>For individual events, schools can send two players per age category (Boys and Girls separately).</li>
          </ul>
        </li>
        <li><strong>Attire:</strong>
          <ul>
            <li>Team participants must wear their school's team sportswear.</li>
            <li>Individual participants must wear appropriate sportswear for their respective events.</li>
          </ul>
        </li>
        <li><strong>Conduct:</strong>
          <ul>
            <li>Participants must maintain sportsmanship and respect towards officials and fellow participants.</li>
            <li>Any form of cheating or misconduct will result in immediate disqualification.</li>
          </ul>
        </li>
        <li><strong>Registration:</strong>
          <ul>
            <li>All entry fees must be paid online at <a href="http://www.lifehisto.com">www.lifehisto.com</a> or via Demand Draft (DD) in favor of Lifehisto Pvt Ltd.</li>
            <li>The DD and participation list must be couriered to GD Goenka School on or before November 1, 2024.</li>
          </ul>
        </li>
        <li><strong>Safety and Liability:</strong>
          <ul>
            <li>Participants must follow safety protocols for each sport.</li>
            <li>Organizers are not liable for any injuries sustained during the events.</li>
          </ul>
        </li>
        <li><strong>Judging and Decisions:</strong>
          <ul>
            <li>All decisions made by the judges and referees are final.</li>
            <li>Protests or appeals must be submitted in writing within 30 minutes of the event’s conclusion.</li>
          </ul>
        </li>
      </ol>

      <h2>Committee Members</h2>
      <ul>
        <li><strong>Organizing Secretary:</strong> Dr. Padala Karthik Reddy</li>
        <li><strong>Event Coordinator:</strong> Harish Pampana</li>
        <li><strong>Arbitration Incharge:</strong> Mohmad Saleem (BCCI and FIDE Qualified Umpire and Arbiter)</li>
        <li><strong>Boys Incharge:</strong> Basker Nathan</li>
        <li><strong>Girls Incharge:</strong> Sonia Patil</li>
        <li><strong>Accommodation and Food Incharge:</strong> Arika Reddy</li>
      </ul>

      <h2>Sponsors and Partners</h2>
      <ul>
        <li><strong>Title Sponsor:</strong> Pending</li>
        <li><strong>Event Sponsor:</strong> Learnorama Technologies Private Limited</li>
        <li><strong>Gift Cards Partner:</strong> Amazon</li>
        <li><strong>Gift Coupons:</strong> VKR Ventures</li>
        <li><strong>Gifting Partner:</strong> Fevicryl Company</li>
        <li><strong>Electronic Media Partner:</strong> Pending</li>
        <li><strong>Print Media Partner:</strong> Pending</li>
      </ul>

      <h2>Additional Sponsorship Opportunities</h2>
      <ul>
        <li><strong>Print Partner:</strong> Opportunity to sponsor all printed materials for the event.</li>
        <li><strong>Co-Sponsorship:</strong> Multiple opportunities for companies to co-sponsor the event.</li>
        <li><strong>Sports Equipment Sponsorship:</strong> Opportunity to provide sports equipment for the events.</li>
        <li><strong>Hospitality Sponsor:</strong> Opportunity to sponsor accommodation and food services for participants and coaches.</li>
        <li><strong>Technology Sponsor:</strong> Provide technological support and equipment for the event.</li>
        <li><strong>Merchandise Sponsor:</strong> Opportunity to supply event merchandise such as T-shirts, caps, and other memorabilia.</li>
        <li><strong>Health and Wellness Sponsor:</strong> Provide medical and wellness services during the event.</li>
      </ul>

      <h2>Important Information</h2>
      <p>
        <strong>Entry Fees:</strong>
        <ul>
          <li>All entry fees should be paid online at <a href="http://www.lifehisto.com">www.lifehisto.com</a>.</li>
          <li>Alternatively, a Demand Draft (DD) in favor of Lifehisto Pvt Ltd can be sent along with the participation list of students.</li>
          <li>Please courier the DD and participation list to GD Goenka School on or before November 1, 2024.</li>
        </ul>
      </p>
      <p>
        <strong>Contact Us:</strong>
        <ul>
          <li><strong>Phone:</strong> 9293225555</li>
          <li><strong>Email:</strong> info@lifehisto.com</li>
          <li><strong>Address:</strong> GD Goenka School, LB Nagar, Hyderabad</li>
          <li><strong>Website:</strong> <a href="http://www.lifehisto.com">www.lifehisto.com</a></li>
        </ul>
      </p>
    </div>
    
    <Footer />
    </>
  );
};

export default OlympiadGames;
